import React, { Component } from "react";
// import AppBar from "../../components/AppBar/AppBar";
import Dashboard from "../Dashboard/Dashboard";

class Home extends Component {
  render() {
    return (
      <div>
        <Dashboard />
      </div>
    );
  }
}

export default Home;
